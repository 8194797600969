/* global $ */

class Home
{
    constructor () 
    {
        import(/* webpackChunkName: "slick" */ '../components/slick').then(() => {
            $('.slick-carousel').slick();
            $('.home-slider').slick({
                dots: false,
                infinite: true,
                autoplay: true,
                autoplaySpeed: 8000,
                speed: 1000,
                fade: true,
                cssEase: 'linear',
                rows: 0,
            });
        });
    }
}

new Home;